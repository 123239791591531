import axios from 'axios';

class ApiCalls {
    constructor() {
        this.host = `https://api.${window.location.host.replace("local.", "")}`
    }

    async getAPI(endPoint) {
        try {
            const response = await axios.get(`${this.host}/${endPoint}`, { withCredentials: true });
            return response.data;
        } catch (error) {
            console.error('Error fetching data:', error.message);
            throw error;
        }
    }

    async postAPI(endPoint, body) {
        try {
            const response = await axios.post(`${this.host}/${endPoint}`, body, { withCredentials: true });
            return response.data;
        } catch (error) {
            console.error('Error posting data:', error.message);
            throw error;
        }
    }

    async patchAPI(endPoint, body) {
        try {
            const response = await axios.patch(`${this.host}/${endPoint}`, body, { withCredentials: true });
            return response.data;
        } catch (error) {
            console.error('Error patching data:', error.message);
            throw error;
        }
    }

    async deleteAPI(endPoint) {
        try {
            const response = await axios.delete(`${this.host}/${endPoint}`, { withCredentials: true });
            return response.data;
        } catch (error) {
            console.error('Error deleting data:', error.message);
            throw error;
        }
    }

    async oidcAPI(endPoint, body) {
        try {
            await axios.post(`${this.host}/${endPoint}`, body).then(response => window.location = response.data.redirect_url);
            return;
        } catch (error) {
            console.error('Error logging in with OIDC:', error.message);
            throw error;
        }
    }

    async logoutAPI(endPoint) {
        try {
            await axios.post(`${this.host}/${endPoint}`, null, { withCredentials: true });
            return;
        } catch (error) {
            console.error('Error while logging out:', error.message);
            throw error;
        }
    }

}

export default ApiCalls;
