import React, { Suspense, lazy, useEffect, useState } from 'react';
import { createTheme, ThemeProvider, CssBaseline } from '@mui/material';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import EffraTrial from '../fonts/Effra_Trial_Rg.ttf'; // Adjust the path to your font file
import LoginPage from './LoginPage';
import authService from './authService';

// Lazy load the Admin component
const Admin = lazy(() => import('./AdminPage'));

const theme = createTheme({
  typography: {
    fontFamily: 'Effra Trial', // Specify your custom font family
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Effra Trial';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Effra Trial'), local('Effra Trial'), url('${EffraTrial}') format('truetype'); // Adjusted URL format to use the imported font file
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `,
    },
  },
});

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(null); // Set initial state to null to indicate loading

  useEffect(() => {
    const checkAuthentication = async () => {
      const authStatus = await authService.isAuthenticated();
      setIsAuthenticated(authStatus);
    };

    checkAuthentication();
  }, []);


  if (isAuthenticated === null) {
    return <div>Loading...</div>; // Show loading indicator while checking authentication
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route
            path="/admin"
            element={
              isAuthenticated ? (
                <Suspense fallback={<div>Loading...</div>}>
                  <Admin />
                </Suspense>
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route path="/" element={<Navigate to="/admin" />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
};

export default App;
