import ApiCalls from './ApiCalls';

const api = new ApiCalls();

const authService = {
    async isAuthenticated() {
        try {
            await api.getAPI(`chains`);
            return true;
        } catch (error) {
            return false;
        }
    },

    async login(email) {
        const OIDC_Data = {
            "email": email,
            "auth_method": "oidc.code",
            "token_response": {
                "type": "cookie"
            }
        };

        try {
            await api.oidcAPI(`auth/login`, OIDC_Data);
            return true;
        } catch (error) {
            return false;
        }
    },

};

export default authService;
