import React, { useState } from 'react';
import '../css/LoginPage.css';
import CrypDefiGradientLogo from '../images/CryptLogoGradient.jpg'; // Import the logo
import EmailIcon from '@mui/icons-material/Email'; // Import EmailIcon from Material-UI
import authService from './authService';

const LoginPage = () => {
    const [email, setEmail] = useState('');

    const handleLogin = async (event) => {
        event.preventDefault(); // Prevents the default form submission behavior, i.e.: refreshing page
        const loggedIn = await authService.login(email);
        if (! loggedIn) {
            alert('OIDC login failed. Please check email.');
            console.error('OIDC login failed. Please check email.');
        }
    };

    return (
        <div className="login-page-container">
            <h1>Welcome to CrypDefi</h1>
            <div className="login-panel">
                <div className="login-box">
                    <img src={CrypDefiGradientLogo} alt="Logo" /> {/* Display the logo */}
                    <h2>Login</h2>
                    <form>
                        <div className="input-box">
                            <EmailIcon sx={{ color: '#00474D' }} /> {/* EmailIcon with color */}
                            <input className="input-field" type="text" value={email} placeholder="Enter your email" onChange={(e) => setEmail(e.target.value)} /> {/* Email input */}
                        </div>
                        <button className="login-button" onClick={handleLogin}>Next</button> {/* Login button */}
                    </form>
                </div>
            </div>
        </div>
    );
};

export default LoginPage;
